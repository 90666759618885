import { useContext, useEffect } from "react"
import Formulario from "./fom"
import { LeadContext } from "../providers/leadContext"


const BrService = () => {
  const { retornoUmLead } = useContext(LeadContext) 

  useEffect(()=>{ 
    retornoUmLead(20)
},[])


    return(
        
        <>
          <body className="flex items-center justify-center w-full  min-h-screen bg-img_bg_brservice bg-no-repeat bg-center bg-cover">
           
            <Formulario/>
          </body>
        </>
    )
}
export default BrService