import { Routes, Route } from "react-router-dom"
import Home from "../page/home"
import BrService from "../brservice/brService"
import Bburguer from "../bburguer/bburguer"
import Privacidade from "../politica/privacidade"

const Rotas=() => {
    return(
        <>
            <Routes>
                <Route exact path={"/"} element={<Home />} />
                <Route path={'/brservice'} element={<BrService/>} />
                <Route path={'/bburguer'} element={<Bburguer/>} />
                <Route path={'/duasbarras'} element={<Privacidade/>} />
            </Routes>
        </>
    )
}

export default Rotas